body.darkMode {
  background-color: #333;

  // Grouping elements with the same color property
  .toolbar mat-icon.material-icons, .material-symbols-outlined, h1.title, p.description, p.result-message, footer {
    color: #fff;
  }

  // Utilizing nesting for hover state
  .history-item:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }

  // Nesting for anchor tag inside p.result-message
  p.result-message {
    a {
      color: #7CACF8 !important;
    }
  }

  // Applying the color to all child elements of .card
  .card * {
    color: white !important;
  }
}
